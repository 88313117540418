import request from '@/utils/request';
export function getShopList() {
  return request({
    url: '/auth/user/shop/list',
    method: 'get',
    params: {
      tmp: new Date().getTime()
    }
  });
}
export function setShopAlias(id, alias) {
  return request({
    url: '/auth/user/shop/setAlias',
    method: 'post',
    data: {
      id: id,
      alias: alias
    }
  });
}
export function changeShopStatus(id) {
  return request({
    url: '/auth/user/shop/status',
    method: 'get',
    params: {
      id: id,
      tmp: new Date().getTime()
    }
  });
}
export function refreshShopToken(id) {
  return request({
    url: '/auth/user/shop/refreshToken?id=' + id,
    method: 'get'
  });
}
export function updatePop(id) {
  return request({
    url: '/auth/user/shop/updatePop',
    method: 'get',
    params: {
      id: id,
      tmp: new Date().getTime()
    }
  });
}