import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.error.cause.js";

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function isPhone(str) {
  return /^1[3-9]\d{9}$/.test(str);
}
export function isDigits(value) {
  return /^-?\d+$/.test(value);
}
export function digitsValidate(rule, value, callback) {
  if (!isDigits(value)) {
    callback(new Error('请输入整数'));
  } else {
    callback();
  }
}
export function isAllChinese(str) {
  return /^[\u4E00-\u9FA5]+$/.test(str);
}
export function validUsername(val) {
  return !!val;
}