import "core-js/modules/es.object.to-string.js";
import { getCountries as _getCountries } from '@/api/auth/country';
var state = {
  countries: []
};
var mutations = {
  SET_COUNTRIES: function SET_COUNTRIES(state, countries) {
    state.countries = countries;
  }
};
var actions = {
  getCountries: function getCountries(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _getCountries().then(function (resp) {
        var countries = resp.data;
        commit('SET_COUNTRIES', countries);
        resolve(countries);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};