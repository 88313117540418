var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  accessToken: function accessToken(state) {
    return state.user.accessToken;
  },
  refreshToken: function refreshToken(state) {
    return state.user.refreshToken;
  },
  user: function user(state) {
    return state.user.info;
  },
  shops: function shops(state) {
    return state.user.shops;
  },
  countries: function countries(state) {
    return state.country.countries;
  },
  logisticsTypes: function logisticsTypes(state) {
    return state.logisticsType.logisticsTypes;
  },
  logisticsTemplates: function logisticsTemplates(state) {
    return state.logisticsType.logisticsTemplates;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  }
};
export default getters;