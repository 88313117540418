import request from '@/utils/request';
export function userMessageList(params) {
  return request({
    url: '/auth/user/message/list',
    method: 'get',
    params: params
  });
}
export function userMessageUnreadCount() {
  return request({
    url: '/auth/user/message/unreadCount',
    method: 'get',
    params: {
      tmp: new Date().getTime()
    }
  });
}
export function readedUserMessage(ids) {
  return request({
    url: '/auth/user/message/readed',
    method: 'post',
    data: {
      ids: ids
    }
  });
}