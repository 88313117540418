import request from '@/utils/request';
export function getTemplateList(status) {
  return request({
    url: '/printorder/logisticsTemplate/list',
    method: 'get',
    params: {
      status: status,
      tmp: new Date().getTime()
    }
  });
}
export function getSmtList(params) {
  return request({
    url: '/printorder/logisticsTemplate/smtList',
    method: 'get',
    params: params
  });
}
export function getYanWenList(params) {
  return request({
    url: '/printorder/logisticsTemplate/yanWenList',
    method: 'get',
    params: params
  });
}
export function getDiSiFangList(params) {
  return request({
    url: '/printorder/logisticsTemplate/diSiFangList',
    method: 'get',
    params: params
  });
}
export function getSmtInfo(id) {
  return request({
    url: '/printorder/logisticsTemplate/smtInfo',
    method: 'get',
    params: {
      id: id,
      tmp: new Date().getTime()
    }
  });
}
export function getYanWenInfo(id) {
  return request({
    url: '/printorder/logisticsTemplate/yanWenInfo',
    method: 'get',
    params: {
      id: id,
      tmp: new Date().getTime()
    }
  });
}
export function changeLogisticsTemplateStatus(id) {
  return request({
    url: '/printorder/logisticsTemplate/status',
    method: 'get',
    params: {
      id: id,
      tmp: new Date().getTime()
    }
  });
}
export function getLogisticsTypes() {
  return request({
    url: '/printorder/logisticsType/list',
    method: 'get',
    params: {
      tmp: new Date().getTime()
    }
  });
}
export function refreshLogisticsTypes() {
  return request({
    url: '/printorder/logisticsType/refresh',
    method: 'get',
    params: {
      tmp: new Date().getTime()
    }
  });
}
export function saveSmtTemplate(data) {
  return request({
    url: '/printorder/logisticsTemplate/smtSave',
    method: 'post',
    data: data
  });
}
export function saveYanWenTemplate(data) {
  return request({
    url: '/printorder/logisticsTemplate/yanWenSave',
    method: 'post',
    data: data
  });
}
export function saveDiSiFangTemplate(data) {
  return request({
    url: '/printorder/logisticsTemplate/diSiFangSave',
    method: 'post',
    data: data
  });
}
export function getLogisticsChannelsList() {
  return request({
    url: '/printorder/logisticsChannel/list',
    method: 'get',
    params: {
      tmp: new Date().getTime()
    }
  });
}
export function saveSmtLogisticsChannel(logisticsServiceIds, logisticsType) {
  return request({
    url: '/printorder/admin/smtLogisticsChannel/save',
    method: 'post',
    data: {
      logisticsServiceIds: logisticsServiceIds,
      logisticsType: logisticsType
    }
  });
}
export function getYanWenLogisticsChannelsList() {
  return request({
    url: '/printorder/yanWenLogisticsChannel/list',
    method: 'get',
    params: {
      tmp: new Date().getTime()
    }
  });
}
export function getDiSiFangLogisticsChannelsList() {
  return request({
    url: '/printorder/diSiFangLogisticsChannel/list',
    method: 'get',
    params: {
      tmp: new Date().getTime()
    }
  });
}
export function refreshLogisticsChannels() {
  return request({
    url: '/printorder/logisticsChannel/refresh',
    method: 'get',
    params: {
      tmp: new Date().getTime()
    }
  });
}
export function refreshYanWenLogisticsChannels() {
  return request({
    url: '/printorder/yanWenLogisticsChannel/refresh',
    method: 'get',
    params: {
      tmp: new Date().getTime()
    }
  });
}
export function refreshDiSiFangLogisticsChannels(accountId) {
  return request({
    url: '/printorder/diSiFangLogisticsChannel/refresh',
    method: 'get',
    params: {
      accountId: accountId,
      tmp: new Date().getTime()
    }
  });
}
export function logisticsTemplateMaxNumber() {
  return request({
    url: '/printorder/logisticsTemplate/maxNumber',
    method: 'get',
    params: {
      tmp: new Date().getTime()
    }
  });
}
export function saveLogisticsTemplateNumber(id, number) {
  return request({
    url: '/printorder/logisticsTemplate/saveNumber',
    method: 'post',
    data: {
      id: id,
      number: number
    }
  });
}
export function getSmtLogisticsTemplateAddresses(shopId) {
  return request({
    url: '/printorder/logisticsTemplate/smtLogisticsTemplateAddresses',
    method: 'get',
    params: {
      shopId: shopId,
      tmp: new Date().getTime()
    }
  });
}
export function saveSmtLogisticsTemplateAddresse(shopId, templateIds, sendAddressEn, sendAddressCn, refundAddressCn) {
  return request({
    url: '/printorder/logisticsTemplate/saveSmtLogisticsTemplateAddresse',
    method: 'post',
    data: {
      shopId: shopId,
      templateIds: templateIds,
      sendAddressEn: sendAddressEn,
      sendAddressCn: sendAddressCn,
      refundAddressCn: refundAddressCn
    }
  });
}