import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.error.cause.js";
import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import qs from 'qs'; // create an axios instance

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000 // request timeout

});
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'; // request interceptor

service.interceptors.request.use(function (config) {
  // do something before request is sent
  // 添加此配置请求restfule接口返回401
  config.headers['X-Requested-With'] = 'XMLHttpRequest';
  config.headers['Accept'] = 'application/json';
  var accessToken = store.getters.accessToken;

  if (accessToken) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['Authorization'] = 'Bearer ' + accessToken;
  }

  if (config.method === 'post' && config.headers.post['Content-Type'] === 'application/x-www-form-urlencoded') {
    config.data = qs.stringify(config.data, {
      indices: false
    });
  }

  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, {
        arrayFormat: 'repeat'
      });
    };
  } // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'


  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var contentType = response.headers['content-type'];

  if (contentType === 'application/json') {
    var res = response.data;

    if (res.code) {
      Message({
        message: res.message || 'error',
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return res;
    }
  } else {
    return response;
  }
}, function (error) {
  console.log('err', error, error.response, error.response.data); // for debug

  if (error && error.response) {
    var refreshToken = store.getters.refreshToken;

    if (refreshToken && refreshToken !== 'undefined' && error.response.status === 401) {
      return store.dispatch('user/refreshToken', refreshToken).then(function (resp) {
        return service(error.config);
      }).catch(function () {
        return store.dispatch('user/resetToken');
      });
    }
  }

  var message = error.message;

  if (error.response && error.response && error.response.data && error.response.data.error_description) {
    message = error.response.data.error_description;
  }

  if (error.response.status === 503) {
    Message({
      type: 'warning',
      message: '系统正在维护，请稍等1分钟。'
    });
  } else if (error.response.status === 401) {
    Message({
      type: 'warning',
      message: '授权已过期，请重新登录。'
    });
  } else {
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    });
  }

  return Promise.reject(error);
});
export default service;