import Cookies from 'js-cookie';
var accessTokenKey = 'smt_cloud_access_token';
var refreshTokenKey = 'smt_cloud_refresh_token';
var shipmentFailureKey = 'shipment_failure_count';
export function getAccessToken() {
  return Cookies.get(accessTokenKey);
}
export function setAccessToken(token) {
  return Cookies.set(accessTokenKey, token, {
    expires: 7
  });
}
export function removeAccessToken() {
  return Cookies.remove(accessTokenKey);
}
export function getRefreshToken() {
  return Cookies.get(refreshTokenKey);
}
export function setRefreshToken(token) {
  return Cookies.set(refreshTokenKey, token);
}
export function removeRefreshToken() {
  return Cookies.remove(refreshTokenKey);
}
export function setShipmentFailureCount(count) {
  return Cookies.set(shipmentFailureKey, count, {
    expires: 12
  });
}
export function getShipmentFailureCount(count) {
  return Cookies.get(shipmentFailureKey);
}