var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c("div", { staticClass: "right-menu" }, [
        _c(
          "div",
          { staticClass: "right-menu-item hover-effect" },
          [
            _c(
              "el-link",
              {
                attrs: {
                  type: "success",
                  target: "_blank",
                  href: "http://help.mornrise.com/printorder/about/",
                  icon: "el-icon-question",
                },
              },
              [_vm._v("使用帮助")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right-menu-item hover-effect" },
          [
            _c(
              "el-dropdown",
              [
                _c(
                  "el-badge",
                  {
                    staticStyle: { display: "inline", "padding-right": "14px" },
                    attrs: { value: _vm.unreadCount },
                  },
                  [
                    _c("i", { staticClass: "el-icon-message-solid" }),
                    _vm._v(" 系统消息 "),
                  ]
                ),
                _c(
                  "el-dropdown-menu",
                  {
                    staticStyle: { width: "300px" },
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _vm._l(_vm.list, function (item) {
                      return _c(
                        "el-dropdown-item",
                        {
                          key: item.id,
                          class: item.isRead ? "msg-line" : "msg-line bold",
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { title: item.subject },
                              on: {
                                click: function ($event) {
                                  return _vm.showMessageDetail(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.subject))]
                          ),
                        ]
                      )
                    }),
                    _c(
                      "el-dropdown-item",
                      {
                        staticStyle: { "text-align": "center" },
                        attrs: { divided: "" },
                      },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                _vm.messageListDialogVisible = true
                              },
                            },
                          },
                          [_vm._v("更多")]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right-menu-item hover-effect" },
          [
            _c(
              "el-link",
              {
                staticStyle: { margin: "0 15px" },
                attrs: {
                  type: "danger",
                  target: "_blank",
                  href:
                    "https://marketplace.aliexpress.com/web/detail.html?articleCode=" +
                    _vm.marketCode,
                },
              },
              [_vm._v("AE服务市场")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right-menu-item hover-effect" },
          [
            _c(
              "el-dropdown",
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _c(
                    "span",
                    [
                      !_vm.isVip()
                        ? _c("svg-icon", { attrs: { "icon-class": "user" } })
                        : _c("svg-icon", {
                            staticStyle: { color: "#ea9518" },
                            attrs: { "icon-class": "vip", title: "VIP用户" },
                          }),
                      _vm._v(" " + _vm._s(_vm.user.nickName) + " "),
                    ],
                    1
                  ),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c("el-dropdown-item", [
                      _c(
                        "a",
                        { attrs: { href: "#/" } },
                        [
                          _c("svg-icon", { attrs: { "icon-class": "home" } }),
                          _vm._v(" 首页 "),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              href: "#/profile/index",
                              underline: false,
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "usercenter" },
                            }),
                            _vm._v(" 个人中心 "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-dropdown-item", { attrs: { divided: "" } }, [
                      _c(
                        "span",
                        {
                          staticStyle: { display: "block" },
                          on: { click: _vm.logout },
                        },
                        [
                          _c("svg-icon", { attrs: { "icon-class": "logout" } }),
                          _vm._v(" 退出登录 "),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "系统消息",
            visible: _vm.messageDetailDialogVisible,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.messageDetailDialogVisible = $event
            },
          },
        },
        [
          _c(
            "h2",
            { staticStyle: { "text-align": "center", "margin-top": "-10px" } },
            [_vm._v(_vm._s(_vm.message.subject))]
          ),
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v(_vm._s(_vm.message.publishTime)),
          ]),
          _c("div", {
            staticClass: "message-content",
            domProps: { innerHTML: _vm._s(_vm.message.content) },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "消息记录",
            visible: _vm.messageListDialogVisible,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.messageListDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                size: "small",
                data: _vm.page.content,
                "element-loading-text": "Loading",
                border: "",
                fit: "",
                stripe: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "标题" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "a",
                          {
                            class: row.isRead ? "" : "bold",
                            on: {
                              click: function ($event) {
                                return _vm.showMessageDetail(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.subject))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "发布时间", width: "160", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(_vm._s(row.publishTime))]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "15px", "text-align": "right" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, prev, pager, next",
                  total: _vm.page.totalElements,
                  "page-size": _vm.page.size,
                  "page-count": _vm.page.totalPages,
                  "current-page": _vm.page.number + 1,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }