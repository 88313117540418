import "core-js/modules/es.object.to-string.js";
import { getLogisticsTypes as _getLogisticsTypes, getTemplateList as _getTemplateList } from '@/api/printorder/logisticsTemplate';
var state = {
  logisticsTypes: [],
  logisticsTemplates: []
};
var mutations = {
  SET_LOGISTICSTYPES: function SET_LOGISTICSTYPES(state, logisticsTypes) {
    state.logisticsTypes = logisticsTypes;
  },
  SET_LOGISTICSTEMPLATES: function SET_LOGISTICSTEMPLATES(state, logisticsTemplates) {
    state.logisticsTemplates = logisticsTemplates;
  }
};
var actions = {
  getLogisticsTypes: function getLogisticsTypes(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _getLogisticsTypes().then(function (resp) {
        var logisticsTypes = resp.data;
        commit('SET_LOGISTICSTYPES', logisticsTypes);
        resolve(logisticsTypes);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getTemplateList: function getTemplateList(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _getTemplateList().then(function (resp) {
        var logisticsTemplates = resp.data;
        commit('SET_LOGISTICSTEMPLATES', logisticsTemplates);
        resolve(logisticsTemplates);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};