import "D:\\workspace\\smt-cloud\\smt-ui\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\workspace\\smt-cloud\\smt-ui\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\workspace\\smt-cloud\\smt-ui\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\workspace\\smt-cloud\\smt-ui\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

import '@/styles/index.scss'; // global css

import App from './App';
import store from './store';
import router from './router';
import '@/icons'; // icon

import '@/permission'; // permission control

import { parseTime } from '@/utils/index';
import moment from 'moment';
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 import { mockXHR } from '../mock'
 if (process.env.NODE_ENV === 'production') {
   mockXHR()
  }
  */
// set ElementUI lang to EN

Vue.use(ElementUI, {
  locale: locale
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});
Vue.prototype.$parseTime = parseTime;
Vue.prototype.$moment = moment;

Vue.prototype.$productImagePath = function (product) {
  var path = '';
  var productImageBaseUrl = '//ae01.alicdn.com/kf/';

  if (product.productAttributes) {
    var arr = JSON.parse(product.productAttributes).sku;
    arr.forEach(function (element) {
      if (element.skuImg) {
        path = element.skuImg;
      }
    });
  }

  if (!path) {
    if (product.snapshotSmallPhotoPath) {
      path = product.snapshotSmallPhotoPath;
    } else if (product.productImgUrl) {
      path = product.productImgUrl;
    }
  }

  if (!path) {
    path = '/img/default.jpg';
  }

  if (path.indexOf('http') !== 0) {
    path = productImageBaseUrl + path;
  }

  return path;
}; // 揽收方式


Vue.prototype.$deliveryMode = {
  pickUp: '上门揽收',
  selfFeeding: '卖家自送',
  selfPost: '快递到仓库'
}; // 交货方式

Vue.prototype.$handoverType = {
  cainiao_pickup: '菜鸟揽收',
  self_send: '自送',
  self_post: '自寄'
}; // 约揽方式

Vue.prototype.$appointmentType = {
  bigbag: '大包预约',
  batch: '批次预约'
};
Vue.prototype.$taxed = {
  'U_TAXED': '海外仓已税',
  'I_TAXED': '跨境已税'
};
Vue.prototype.$languageMap = {
  en: '英语',
  ru: '俄语',
  spa: '西班牙语',
  pt: '葡萄牙语',
  fra: '法语',
  de: '德语',
  jp: '日语',
  kor: '韩语',
  th: '泰语',
  ara: '阿拉伯语',
  it: '意大利语',
  el: '希腊语',
  nl: '荷兰语',
  pl: '波兰语',
  dan: '丹麦语',
  fin: '芬兰语'
};
Vue.prototype.$trackingStatus = {
  transit: '运输途中',
  delivery: '派送中',
  back: '包裹退回',
  arrive: '到达待取',
  delivered: '已经妥投',
  failed_attempt: '投递失败'
};