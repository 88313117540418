import _typeof from "D:/workspace/smt-cloud/smt-ui/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import store from '@/store';
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */

export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
/**
 * @param {string} url
 * @returns {Object}
 */

export function param2Obj(url) {
  var search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
export function messageParamMap() {
  return {
    '{orderId}': '订单号{orderId}',
    '{buyerName}': '买家姓名{buyerName}',
    '{sellerName}': '卖家姓名{sellerName}',
    '{intlTrackingNo}': '国际运单号{intlTrackingNo}',
    '{logisticsType}': '物流方式{logisticsType}'
  };
}

function accMul(arg1, arg2) {
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString();

  try {
    m += s1.split('.')[1].length;
  } catch (e) {
    /** */
  }

  try {
    m += s2.split('.')[1].length;
  } catch (e) {
    /** */
  }

  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
}

export function customsDeclarationDisplay(cd) {
  var str = cd.categoryEnDesc + '/' + cd.categoryCnDesc + (cd.hsCode ? '/' + cd.hsCode : '') + (cd.aneroidMarkup ? '/非液体化妆品' : '') + (cd.breakable ? '/易碎' : '') + (cd.containsBattery ? '/含电池' : '') + (cd.onlyBattery ? '/纯电池' : '') + (cd.productDeclareAmount ? '/$' + cd.productDeclareAmount : '') + (cd.productWeight ? '/' + accMul(cd.productWeight, cd.productCount) + 'kg(' + cd.productCount + '*' + cd.productWeight + 'kg)' : '');
  return str;
}
export function productSkuDisplay(attr) {
  var display = '';

  if (attr) {
    var json = JSON.parse(attr);

    if (json.sku && json.sku.length) {
      for (var i = 0; i < json.sku.length; i++) {
        var item = json.sku[i];
        display += item.pName + ':' + (item.selfDefineValue ? item.selfDefineValue : item.pValue) + ' ';
      }
    }
  }

  return display;
}
export function countryName(code) {
  var countries = store.getters.countries;
  var country;

  if (countries) {
    country = countries.find(function (item) {
      return item.code === code;
    });
  }

  return country ? country.cnName : code;
}
export function openPayment(token) {
  var form = document.createElement('form');
  form.setAttribute('action', process.env.VUE_APP_BASE_API + '/auth/pay');
  form.setAttribute('method', 'post');
  form.setAttribute('target', '_blank');
  form.innerHTML = '<input type="hidden" name="access_token" value="' + token + '"/>';
  document.body.appendChild(form);
  form.submit();
}
export function applyTrackingNoCheck(wyPackage) {
  if (!wyPackage.logisticsTemplateId) {
    throw new Error('包裹[' + wyPackage.packageNo + ']未设置物流方式');
  }

  var alreadyTaxedCount = 0;
  var notAlreadyTaxedCount = 0;

  for (var i = 0; i < wyPackage.orders.length; i++) {
    var order = wyPackage.orders[i];

    for (var j = 0; j < order.products.length; j++) {
      var product = order.products[j];

      if (!product.customsDeclaration) {
        throw new Error('包裹[' + wyPackage.packageNo + ']下的商品[' + product.skuCode + ']未设置报关单');
      }

      if (product.alreadyTaxed) {
        alreadyTaxedCount++;
      } else {
        notAlreadyTaxedCount++;
      }
    }
  }

  if (alreadyTaxedCount > 0 && notAlreadyTaxedCount > 0) {// throw new Error('包裹[' + wyPackage.packageNo + ']同时包含已税和未税的商品不能发货，请将包裹进行拆分')
  }
}
export function resolveBlob(res, mimeType) {
  var aLink = document.createElement('a');
  var blob = new Blob([res.data], {
    type: mimeType
  });
  var contentDisposition = decodeURI(res.headers['content-disposition']);
  var fileName = contentDisposition.substring('attachment; filename='.length);
  aLink.href = URL.createObjectURL(blob);
  aLink.setAttribute('download', fileName); // 设置下载文件名称

  document.body.appendChild(aLink);
  aLink.click();
  document.body.appendChild(aLink);
}