import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

var modules = process.env.VUE_APP_MODULES;
var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/logisticsTypeList',
  component: function component() {
    return import('@/views/logisticsTypeList/index');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, {
  path: '/payReturn',
  component: function component() {
    return import('@/views/payReturn/index');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: 'profile/index',
  hidden: true,
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/auth/dashboard/index');
    },
    name: '首页',
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: 'profile/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/auth/profile/index');
    },
    name: '个人中心',
    meta: {
      title: '个人中心',
      icon: 'user',
      affix: true
    }
  }]
}];
var asyncRoutes = [];

if (modules.indexOf('printorder') !== -1 || modules === 'all') {
  asyncRoutes.push({
    path: '/popPurchaseOrder',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '无忧半托管',
      icon: '半托管'
    },
    redirect: '/popPurchaseOrder/index',
    children: [{
      path: 'index',
      component: function component() {
        return import('@/views/printorder/popPurchaseOrder/index');
      },
      name: '采购单打印',
      meta: {
        title: '采购单打印',
        icon: 'orderlist'
      }
    }, {
      path: 'pickup',
      component: function component() {
        return import('@/views/printorder/popPurchaseOrder/popPickUp/index');
      },
      name: '揽收单打印',
      meta: {
        title: '揽收单打印',
        icon: 'orderlist'
      },
      children: [{
        path: 'inbound',
        name: '待出库',
        component: function component() {
          return import('@/views/printorder/popPurchaseOrder/popPickUp/inbound');
        },
        meta: {
          title: '待出库',
          icon: '待出库'
        }
      }, {
        path: 'outbound',
        name: '已出库',
        component: function component() {
          return import('@/views/printorder/popPurchaseOrder/popPickUp/outbound');
        },
        meta: {
          title: '已出库',
          icon: 'sorting'
        }
      }]
    }, {
      path: 'logisticsTemplate',
      component: function component() {
        return import('@/views/printorder/popPurchaseOrder/logisticsTemplate/pop');
      },
      name: '物流设置',
      meta: {
        title: '物流设置',
        icon: 'logistics'
      }
    }]
  }, {
    path: '/printorder',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '无忧打单',
      icon: 'print'
    },
    redirect: 'printorder/wyPackage/pending',
    children: [{
      path: 'dashboard',
      component: function component() {
        return import('@/views/printorder/dashboard/index');
      },
      meta: {
        title: '订单统计',
        icon: 'dashboard',
        affix: true
      }
    }, {
      path: 'wyPackage',
      component: function component() {
        return import('@/views/printorder/wyPackage/index');
      },
      name: '订单打印',
      meta: {
        title: '订单打印',
        icon: 'orderlist',
        affix: true
      },
      redirect: '/printorder/wyPackage/pending',
      children: [{
        path: 'pending',
        component: function component() {
          return import('@/views/printorder/wyPackage/pending');
        },
        name: '待审核',
        meta: {
          title: '待审核',
          activeMenu: '/printorder/wyPackage'
        },
        hidden: true
      }, {
        path: 'audited',
        component: function component() {
          return import('@/views/printorder/wyPackage/audited');
        },
        name: '待处理',
        meta: {
          title: '待处理',
          activeMenu: '/printorder/wyPackage'
        },
        hidden: true
      }, {
        path: 'applied',
        component: function component() {
          return import('@/views/printorder/wyPackage/applied');
        },
        name: '申请运单号',
        meta: {
          title: '申请运单号',
          activeMenu: '/printorder/wyPackage'
        },
        hidden: true
      }, {
        path: 'printing',
        component: function component() {
          return import('@/views/printorder/wyPackage/printing');
        },
        name: '待打印',
        meta: {
          title: '待打印',
          activeMenu: '/printorder/wyPackage'
        },
        hidden: true
      }, {
        path: 'shipped',
        component: function component() {
          return import('@/views/printorder/wyPackage/shipped');
        },
        name: '声明发货',
        meta: {
          title: '声明发货',
          activeMenu: '/printorder/wyPackage'
        },
        hidden: true
      }, {
        path: 'exception',
        component: function component() {
          return import('@/views/printorder/wyPackage/exception');
        },
        name: '异常订单',
        meta: {
          title: '异常订单',
          activeMenu: '/printorder/wyPackage'
        },
        hidden: true
      }]
    }, {
      path: 'groupPackage',
      component: function component() {
        return import('@/views/printorder/groupPackage/index');
      },
      meta: {
        title: '预约交货',
        icon: 'box'
      },
      redirect: '/printorder/groupPackage/sorting',
      children: [{
        path: 'draft',
        component: function component() {
          return import('@/views/printorder/groupPackage/draft');
        },
        meta: {
          title: '待出库',
          icon: '待出库'
        }
      }, {
        path: 'sended',
        component: function component() {
          return import('@/views/printorder/groupPackage/commit');
        },
        meta: {
          title: '已出库',
          icon: 'sorting'
        }
      }, {
        path: 'warehouse',
        component: function component() {
          return import('@/views/printorder/admin/warehouse/index');
        },
        meta: {
          title: '仓库管理',
          icon: 'table',
          roles: ['admin']
        }
      }]
    }, {
      path: 'logisticsTemplate',
      name: '物流设置',
      component: function component() {
        return import('@/views/printorder/logisticsTemplate/index');
      },
      meta: {
        title: '物流设置',
        icon: 'logistics'
      },
      redirect: '/printorder/logisticsTemplate/smtList',
      children: [{
        path: 'smtList',
        component: function component() {
          return import('@/views/printorder/logisticsTemplate/smtList');
        },
        name: '速卖通线上物流',
        meta: {
          title: '速卖通线上',
          icon: 'shoping'
        }
      }, {
        path: 'yanwenList',
        component: function component() {
          return import('@/views/printorder/logisticsTemplate/yanwenList');
        },
        name: '燕文物流',
        meta: {
          title: '线下燕文',
          icon: 'y'
        }
      }, {
        path: 'disifangList',
        component: function component() {
          return import('@/views/printorder/logisticsTemplate/diSiFangList');
        },
        name: '递四方物流',
        meta: {
          title: '线下递四方',
          icon: '4px'
        }
      }, {
        path: 'topalinkList',
        component: function component() {
          return import('@/views/printorder/logisticsTemplate/topalink');
        },
        name: '通邮物流',
        meta: {
          title: '线下通邮',
          icon: 'ty'
        }
      }, {
        path: 'customList',
        component: function component() {
          return import('@/views/printorder/logisticsTemplate/custom');
        },
        name: '自定义物流',
        meta: {
          title: '自定义物流',
          icon: 'custom'
        }
      }, {
        path: 'smtAddress',
        component: function component() {
          return import('@/views/printorder/logisticsTemplate/smtAddress');
        },
        name: '批量地址设置',
        meta: {
          title: '批量地址设置',
          activeMenu: '/printorder/logisticsTemplate/smtList'
        },
        hidden: true
      }, {
        path: 'editSmt',
        component: function component() {
          return import('@/views/printorder/logisticsTemplate/editSmt');
        },
        name: '速卖通线上物流设置',
        meta: {
          title: '速卖通线上物流设置',
          icon: 'user',
          activeMenu: '/printorder/logisticsTemplate/smtList'
        },
        hidden: true
      }, {
        path: 'editYanwen',
        component: function component() {
          return import('@/views/printorder/logisticsTemplate/editYanwen');
        },
        name: '燕文航空物流设置',
        meta: {
          title: '燕文航空物流设置',
          icon: 'user',
          activeMenu: '/printorder/logisticsTemplate/yanwenList'
        },
        hidden: true
      }]
    }, {
      path: 'logisticsRule',
      name: '物流规则',
      component: function component() {
        return import('@/views/printorder/logisticsRule/list');
      },
      meta: {
        title: '物流规则',
        icon: 'rule'
      }
    }, {
      path: 'customsDeclaration',
      name: '报关单模板',
      component: function component() {
        return import('@/views/printorder/customsDeclaration/list');
      },
      meta: {
        title: '报关单模板',
        icon: 'bgd'
      }
    }, {
      path: 'filterRule',
      name: '审单规则',
      component: function component() {
        return import('@/views/printorder/filterRule/list');
      },
      meta: {
        title: '审单规则',
        icon: 'filter'
      }
    }, {
      path: 'messageTemplate',
      name: '消息模板',
      component: function component() {
        return import('@/views/printorder/messageTemplate/list');
      },
      meta: {
        title: '消息模板',
        icon: 'messageTemplate'
      }
    }, {
      path: 'address',
      meta: {
        title: '地址管理',
        icon: 'addressmenage'
      },
      component: function component() {
        return import('@/views/printorder/address/index');
      }
    }]
  });
} // 自动评价


if (modules.indexOf('evaluation') !== -1 || modules === 'all') {
  asyncRoutes.push({
    path: '/evaluation',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '自动评价',
      icon: 'message'
    },
    redirect: 'evaluation/settings',
    children: [{
      path: 'settings',
      component: function component() {
        return import('@/views/evaluation/settings');
      },
      meta: {
        title: '评价设置',
        icon: 'settings'
      }
    }, {
      path: 'waitingList',
      component: function component() {
        return import('@/views/evaluation/waitingList');
      },
      meta: {
        title: '等待评价',
        icon: 'orderlist'
      }
    }, {
      path: 'successList',
      component: function component() {
        return import('@/views/evaluation/successList');
      },
      meta: {
        title: '评价记录',
        icon: 'table'
      }
    }, {
      path: 'templates',
      component: function component() {
        return import('@/views/evaluation/templates');
      },
      meta: {
        title: '评价模板',
        icon: 'messageTemplate'
      }
    }]
  });
} // 订单延期


if (modules.indexOf('delayorder') !== -1 || modules === 'all') {
  asyncRoutes.push({
    path: '/delayorder',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '订单延期',
      icon: '延期'
    },
    redirect: 'delayorder/settings',
    children: [{
      path: 'settings',
      component: function component() {
        return import('@/views/delayorder/settings');
      },
      meta: {
        title: '默认策略',
        icon: 'settings'
      }
    }, {
      path: 'customRule',
      component: function component() {
        return import('@/views/delayorder/customRule');
      },
      meta: {
        title: '自定义策略',
        icon: '自定义'
      }
    }, {
      path: 'log',
      component: function component() {
        return import('@/views/delayorder/log');
      },
      meta: {
        title: '延期记录',
        icon: 'table'
      }
    }]
  });
} // 货到催收


if (modules.indexOf('remindgoods') !== -1 || modules === 'all') {
  asyncRoutes.push({
    path: '/remindgoods',
    component: Layout,
    // alwaysShow: true,
    meta: {
      title: '物流追踪',
      icon: 'logistics',
      roles: ['admin']
    },
    redirect: 'remindgoods/orderList',
    children: [{
      path: 'settings',
      component: function component() {
        return import('@/views/remindgoods/settings');
      },
      meta: {
        title: '追踪设置',
        icon: 'settings'
      }
    }, {
      path: 'orderList',
      component: function component() {
        return import('@/views/remindgoods/orderList');
      },
      meta: {
        title: '物流追踪',
        icon: 'logistics',
        roles: ['admin']
      }
    },
    /*
    {
      path: 'successOrderList',
      component: () => import('@/views/remindgoods/successOrderList'),
      meta: { title: '催取成功', icon: 'success' }
    }, {
      path: 'templates',
      component: () => import('@/views/remindgoods/templates'),
      meta: { title: '催取模板', icon: 'messageTemplate' }
    },
    */
    {
      path: 'trackingStatusRule',
      component: function component() {
        return import('@/views/remindgoods/admin/trackingStatusRule');
      },
      meta: {
        title: '追踪匹配',
        icon: 'filter',
        roles: ['admin']
      }
    }]
  });
}
/*
// 订单催付
if (modules.indexOf('remindpayment') !== -1 || modules === 'all') {
  asyncRoutes.push({
    path: '/remindpayment',
    component: Layout,
    alwaysShow: true,
    meta: { title: '订单催付', icon: '催付' },
    redirect: 'remindpayment/settings',
    children: [
      {
        path: 'settings',
        component: () => import('@/views/remindpayment/settings'),
        meta: { title: '催付设置', icon: 'settings' }
      }, {
        path: 'template',
        component: () => import('@/views/remindpayment/template'),
        meta: { title: '催付模板', icon: 'messageTemplate' }
      }, {
        path: 'smtOrder',
        component: () => import('@/views/remindpayment/smtOrder'),
        meta: { title: '催付记录', icon: 'table' }
      }]
  })
}
*/
// 关联营销


if (modules.indexOf('relatedmarketing') !== -1 || modules === 'all') {
  asyncRoutes.push({
    path: '/relatedmarketing',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '关联营销',
      icon: 'hyperlinks'
    },
    redirect: 'relatedmarketing/template',
    children: [{
      path: 'template',
      component: function component() {
        return import('@/views/relatedmarketing/template');
      },
      meta: {
        title: '模板设置',
        icon: 'settings'
      }
    }, {
      path: 'product',
      component: function component() {
        return import('@/views/relatedmarketing/product');
      },
      meta: {
        title: '商品列表',
        icon: 'table'
      }
    }]
  });
}
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */


asyncRoutes.push({
  path: '/admin',
  component: Layout,
  alwaysShow: true,
  meta: {
    title: '系统管理',
    icon: 'settings',
    roles: ['admin']
  },
  redirect: 'user',
  children: [{
    path: 'user',
    component: function component() {
      return import('@/views/auth/admin/user/index');
    },
    meta: {
      title: '用户管理',
      icon: 'user',
      roles: ['admin']
    }
  }, {
    path: 'shop',
    component: function component() {
      return import('@/views/auth/admin/shop/index');
    },
    name: '店铺管理',
    meta: {
      title: '店铺管理',
      icon: 'shop',
      roles: ['admin']
    }
  }, {
    path: 'userOrder',
    component: function component() {
      return import('@/views/auth/admin/userOrder/index');
    },
    meta: {
      title: '缴费记录',
      icon: 'money',
      roles: ['admin']
    }
  }, {
    path: 'client',
    component: function component() {
      return import('@/views/auth/admin/client/index');
    },
    meta: {
      title: '应用管理',
      icon: 'app',
      roles: ['admin']
    }
  }, {
    path: 'message',
    component: function component() {
      return import('@/views/auth/admin/message/index');
    },
    name: '消息管理',
    meta: {
      title: '消息管理',
      icon: 'message',
      roles: ['admin']
    }
  }, {
    path: 'country',
    name: '国家编码',
    component: function component() {
      return import('@/views/printorder/admin/country/index');
    },
    meta: {
      title: '国家编码',
      icon: 'country',
      roles: ['admin']
    },
    redirect: '/admin/country/list',
    children: [{
      path: 'list',
      component: function component() {
        return import('@/views/auth/admin/country/list');
      },
      name: '速卖通国家编码',
      meta: {
        title: '速卖通国家编码',
        icon: 'shoping'
      }
    }, {
      path: 'yawenList',
      component: function component() {
        return import('@/views/printorder/admin/country/yanWenList');
      },
      name: '燕文国家编码',
      meta: {
        title: '燕文国家编码',
        icon: 'y'
      }
    }, {
      path: 'isoList',
      component: function component() {
        return import('@/views/printorder/admin/country/isoList');
      },
      name: 'ISO国家编码',
      meta: {
        title: 'ISO国家编码',
        icon: 'iso'
      }
    }]
  }, {
    path: 'logisticsChannel',
    name: '速卖通渠道',
    component: function component() {
      return import('@/views/printorder/admin/logisticsChannel/list');
    },
    meta: {
      title: '速卖通渠道',
      icon: 'logistics',
      roles: ['admin']
    }
  }, {
    path: 'countryLanguage',
    component: function component() {
      return import('@/views/auth/admin/countryLanguage');
    },
    meta: {
      title: '官方语言',
      icon: 'global',
      roles: ['admin']
    }
  }, {
    path: 'financeRate',
    component: function component() {
      return import('@/views/printorder/admin/financeRate');
    },
    meta: {
      title: '汇率设置',
      icon: 'dollar',
      roles: ['admin']
    }
  }]
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
});
export { constantRoutes, asyncRoutes };

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;