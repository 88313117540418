import request from '@/utils/request';
export function login(data) {
  data.grant_type = 'password';
  data.scope = process.env.VUE_APP_AUTH_SCOPE;
  return request({
    headers: {
      Authorization: process.env.VUE_APP_AUTH_BASIC
    },
    url: '/auth/oauth/token',
    method: 'post',
    data: data
  });
}
export function logout() {
  return request({
    url: '/auth/revokeToken',
    method: 'get'
  });
}
export function aliexpressLogin(code) {
  return request({
    headers: {
      Authorization: process.env.VUE_APP_AUTH_BASIC
    },
    url: '/auth/oauth/token',
    method: 'post',
    data: {
      grant_type: 'aliexpress_code',
      code: code
    }
  });
}
export function refreshToken(refresh_token) {
  return request({
    headers: {
      Authorization: process.env.VUE_APP_AUTH_BASIC
    },
    url: '/auth/oauth/token',
    method: 'post',
    data: {
      grant_type: 'refresh_token',
      refresh_token: refresh_token
    }
  });
}
export function getInfo() {
  return request({
    url: '/auth/user/info',
    method: 'get'
  });
}
export function checkPhone(phone) {
  return request({
    url: '/auth/checkPhone',
    method: 'get',
    params: {
      phone: phone,
      tmp: new Date().getTime()
    }
  });
}
export function checkUserPhone(phone) {
  return request({
    url: '/auth/user/checkPhone',
    method: 'get',
    params: {
      phone: phone,
      tmp: new Date().getTime()
    }
  });
}
export function checkUserEmail(email) {
  return request({
    url: '/auth/user/checkEmail',
    method: 'get',
    params: {
      email: email,
      tmp: new Date().getTime()
    }
  });
}
export function register(data) {
  return request({
    url: '/auth/register',
    method: 'post',
    data: data
  });
}
export function updateUser(data) {
  return request({
    url: '/auth/user/update',
    method: 'post',
    data: data
  });
}
export function updatePass(oldPassword, newPassword) {
  return request({
    url: '/auth/user/updatePass',
    method: 'post',
    data: {
      oldPassword: oldPassword,
      newPassword: newPassword
    }
  });
}
export function resetPassword(phone, password, code) {
  return request({
    url: '/auth/reset',
    method: 'post',
    data: {
      phone: phone,
      password: password,
      code: code
    }
  });
}
export function sendRegisterSmsCode(phone) {
  return request({
    url: '/auth/sendRegisterSmsCode',
    method: 'post',
    data: {
      phone: phone
    }
  });
}
export function sendResetSmsCode(phone) {
  return request({
    url: '/auth/sendResetSmsCode',
    method: 'post',
    data: {
      phone: phone
    }
  });
}
export function changePassword(oldPassword, newPassword) {
  return request({
    url: '/auth/user/changePassword',
    method: 'post',
    data: {
      oldPassword: oldPassword,
      newPassword: newPassword
    }
  });
}