import request from '@/utils/request';
export function getCountries() {
  return request({
    url: '/auth/country/list',
    method: 'get',
    params: {
      tmp: new Date().getTime()
    }
  });
}
export function getCountryList(params) {
  return request({
    url: '/auth/admin/country/list',
    method: 'get',
    params: params
  });
}
export function saveCountry(data) {
  return request({
    url: '/auth/admin/country/save',
    method: 'post',
    data: data
  });
}
export function deleteCountry(code) {
  return request({
    url: '/auth/admin/country/delete',
    method: 'get',
    params: {
      code: code
    }
  });
}